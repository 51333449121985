@import '@/styles/variables.scss';




















































































































.radio-group-language {
  .v-label {
    width: 100%;
  }
  .label-language {
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }
}
.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
